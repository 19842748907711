@import "../../../styles/variables";

.panel-component-first,
.panel-header-second {

  .flex-column {
    display: flex;
    align-items: flex-start;
    word-break: break-word;
    text-align: left;
  }
  .block__value {
    font-size: 14px;
    font-weight: 500;
  }

  .block__label {
    font-size: 12px;
    font-weight: 400;
  }
}

.expand-all-button {
  background-color: $label-green;
  font-size: 8px;
  border-radius: 32px;
  width: 97px;
  margin-top: 10px;
  height: 28px;
}

.header-details {
  .ant-col {
    margin-top: -10px;
  }
}

.panel-header-second {
  margin-top: 30px;
}

.collapse-icon-container {
  width: 32px;
  height: 32px;
  background: $box-bg 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  transition: transform 0.3s ease;

  &.rotate {
    transform: rotate(180deg);
  }
}

.payment-history {
  width: 100%;
}

.payment-row {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-year {
  width: 100px;
  text-align: left;
}

.year-flex {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 15px;

  .payment-status, .month {
    flex-basis: 70px;
    text-align: center;
  }
}
