@import '../../../styles/variables';

.cash-on-hand {

  &.date-range {
    margin-bottom: 10px;

    .ant-picker-range{
      border-radius: 12px;
      border-color: $input-border-color;
      box-shadow: none;

      &.ant-picker-focused{
        border-radius: 12px;
        border-color: $input-border-color;
        box-shadow: none;
      }

      &:hover{
        border-color: $input-hover-color;
      }
    }
  }

  &__popover {
    padding: 1rem;
    width: 280px;
    img {
      width: 25px;
    }
  }
}
