.actions {
  visibility: hidden;
  opacity: 0;
}
.ant-table-row {
  &:hover {
    .actions {
      visibility: visible;
      opacity: 1;
    }
  }
}
