@import "../../styles/variables";
@import "../../styles/mixins";

.profile {
  @include flex-container(16px, column);
  margin: 12px;
  .ant-row {
    padding: 16px 32px;
  }
  &__pic {
    border-radius: 12px;
    box-shadow: $primary-shadow;
  }
  &__role {
    padding: 12px;
    background-color: #ebf2f0;
    color: #105f49;
    width: fit-content;
    border-radius: 12px;
  }
  &__attr {
    &__value {
      font-weight: bold;
    }
  }
  &__pass {
    cursor: pointer;
    color: $link-color;
  }
  &__modal {
    .ant-modal-content {
      padding: 0;
      width: 560px;
      height: 550px;
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}
