@import "../../../styles/variables";

.custom-document {
  img {
    border-radius: 12px;
    object-fit: cover;
    height: auto;
  }
  &__modal-img {
    max-width: 100%;
    height: 40rem;
    padding: 20px;

    &.loading-image {
      object-fit: contain;
      filter: blur(10px);
      transition: filter 0.3s ease-out;
    }
  
    &.loaded-image {
      filter: none;
      transition: none;
    }
  }

  &__lazy-image {
    position: relative;
  }

  &__loading-image {
    object-fit: contain;
    filter: blur(10px);
    transition: filter 0.3s ease-out;
  }

  &__loaded-image {
    filter: none;
    transition: none;
  }
}

.ant-modal-content {
  padding: 1rem;
}
.doc-container__iframe {
  width: 100%;
  height: 100%;
}

.pdf-modal {
  top: 7.5rem;
}

.preSignedUrlContainer {
  display: flex;
  gap: 2rem;
  flex-flow: column wrap;

  .urlContainer {
    border: 1px dotted $text-gray;
    padding: 1rem;
    display: flex;
    align-items: center;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;

    a {
      max-width: 2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 40rem;
      vertical-align: middle;
      padding-left: 0.5rem;
    }
  }
}
