@import "../../../../styles/variables";
@import "../../../../styles/mixins";
.loan-type-condition-form {
  @include flex-container(30px, column);
  &__container {
    padding: 3rem;
    height: auto;
    width: 470px;
    border-radius: 8px;
    background-color: $body-bg;
    .ant-col {
      margin-bottom: 20px;
    }
  }

  &__header {
    @include flex-center();
    justify-content: flex-start;

    &__process {
      &-count {
        background-color: #165151;
        border-radius: 100%;
        color: #fff;
        height: 32px;
        width: 32px;
        @include flex-center();
      }

      &-title {
        color: $primary-color;
        font-size: 24px;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
}
.drawer-footer__add {
  margin-top: 3rem !important;
}
