@import "src/styles/variables";

.radio-field {
  .ant-radio-group {
    display: block;
    margin-top: 0.5rem;
    .ant-radio-wrapper {
      margin-right: 20px;
      .ant-radio-checked {
      }
    }
  }
  .ant-radio-checked {
    .ant-radio-inner::after {
      border-color: $primary-color;
    }
  }
}
