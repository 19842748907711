.loan-type-condition {
  padding: 2rem;
  .content-box {
    padding: 2rem !important;
  }
  &__row {
    align-items: center;
    justify-content: center;
  }
  &__wrapper {
    .ant-col {
      flex-wrap: nowrap;
    }
  }
}
