@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.documents {
  &-modal {
    .ant-modal-body {
      @include flex-center(0, column);
      padding-top: 50px;

      iframe {
        flex-grow: 1;
        width: 100%;
      }
    }
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    left: $document-spinner-left-position;
   };

  &__wrapper {
    background-color: #f2f8f8;
    &-label {
      padding: 10px;
      border-bottom: 1px solid #d8e5e1;
    }
    border-radius: 8px;
  }
}
