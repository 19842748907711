.velicham-repayment {
  .title {
    font-size: 2rem;
    color: #063d1e;
    font-weight: 600;
  }
  &__header {
    display: flex;
  }
  .block__label,
  .block__value {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .ant-drawer-title {
    font-size: 20px !important;
  }
  .ant-drawer-body {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  .block__label {
    text-align: left !important;
  }
}
