@import "variables";

/* Input Field */
.ant-input {
  border-radius: $input-border-radius;
  border-color: $input-border-color;
  padding: 1.5rem 2rem 1.3rem 2rem;
  outline: none;

  &:is(:focus, :hover) {
    border-color: $input-hover-color;
  }

  &-focused,
  &:focus,
  &:focus-visible {
    box-shadow: none;
    outline: none;
  }
}

// Input with prefix
.ant-form-item-control-input {
  &:focus-visible {
    outline: none;
  }
}

.ant-input-affix-wrapper {
  align-items: center;
  padding: 0;
  @extend .ant-input;

  .ant-input {
    border-radius: 0;
    border-color: transparent;

    &:focus {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px white inset !important;
    }
  }

  &.ant-input-password .ant-input {
    padding-left: 20px;
    border-radius: $input-border-radius;
  }

  &:is(:focus, :hover, :active),
  &-focused {
    border-color: $input-hover-color !important;
    box-shadow: none;
    outline: none;
  }

  .ant-input-prefix,
  .ant-input-suffix {
    height: 13px;
    margin-inline: 8px;
    width: 18px;

    & img {
      height: 100%;
      width: 100%;
    }
  }
}
/* Ant Row */
.ant-row {
  .ant-form-item-label {
    font-weight: 600;
  }
}
/* Button */

.ant-btn {
  /* Primary button */
  background-color: $button-default-bg-color;
  color: $button-default-text-color;
  border-color: $button-default-border-color;
  border-radius: $button-default-border-radius;
  text-transform: uppercase;

  &.btn--outline {
    color: $button-default-bg-color;
    background: $button-default-text-color;
    font-weight: 600;
  }

  &:is(.ant-btn-primary, :hover, :focus, :active) {
    background-color: $button-primary-bg-color;
    color: $button-primary-text-color;
    border-color: $button-primary-border-color;
    border-radius: $button-primary-border-radius;
  }
}

/* Checkbox */
.ant-checkbox-wrapper {
  align-items: center;

  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 6px;
      width: 24px;
      height: 24px;
    }

    .ant-checkbox-inner {
      border-color: $checkbox-border-color;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $checkbox-hover-border-color;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $checkbox-bg-color;
      border-color: $checkbox-hover-border-color !important;

      &::after {
        left: 35%;
      }
    }

    &::after {
      border: 0;
    }
  }
}

/* Radio Group */
.ant-radio {
  .ant-radio-inner {
    border-color: $radio-border-color !important;
  }

  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: $radio-checked-border-color !important;

      &::after {
        background-color: $radio-checked-bg-color;
      }
    }
  }
}

/* Radio solid */
.ant-radio-group.ant-radio-group-solid {
  .ant-radio-button-wrapper {
    &:hover {
      color: $checkbox-hover-text-color;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: $radio-checked-bg-color;
      border-color: $radio-checked-border-color !important;
      color: $checkbox-text-color;

      &::before {
        background-color: $radio-checked-bg-color !important;
      }
    }
  }
}

/* Select */
.ant-select {
  width: 100%;

  &:focus-visible {
    box-shadow: $input-hover-color !important;
    outline: none !important;
  }

  &:not(.ant-select-disabled) {
    .ant-select-selector {
      border-color: $select-border-color !important;
      outline: none !important;

      &:hover {
        border-color: $input-hover-color !important;
      }
    }
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: $input-border-radius;
    border-color: $input-border-color;
    padding: 10px 20px 10px 20px;
    height: 100%;
  }

  &.ant-select-open {
    .ant-select-selector {
      box-shadow: $input-hover-color !important;
    }
  }
}

// Tabs
.ant-tabs {
  &.ant-tabs-top {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        &:nth-of-type(1) {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:nth-last-of-type(2) {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0;

    .ant-tabs-nav-wrap {
      align-self: center;
    }
  }

  &-nav-list {
    .ant-tabs-tab {
      background-color: #fff;
      border: 1px solid $tab-border;
      color: $tab-color;
      font-size: 1.5rem;
      padding-inline: 32px 34px;

      &:not(:last-child),
      &:not(:first-child) {
        border-width: 0.5px;
      }

      &-active {
        color: $tab-active-color;
        font-weight: normal;
        transition: color 1s ease-in;
      }
    }
  }
}

// Badge
.ant-badge {
  .ant-badge-count {
    padding: 5px 20px;
    border-radius: 15px;
    font-weight: bold;
    vertical-align: middle;
    text-transform: capitalize;
    text-align: center;
    height: auto;
  }

  &.badge-active {
    .ant-badge-count {
      background-color: #e9f8f1;
      color: $success-color;
    }
  }
}

.ant-layout:not(.ant-layout-has-sider) {
  .ant-layout-header {
    padding-inline: 36px;
    border-bottom: 1px solid #d8e5e1;
  }

  .ant-layout-content {
    padding: 18px 36px;
  }

  .ant-tabs {
    gap: 30px;

    &-tab {
      &-btn {
        text-transform: capitalize;
      }
    }

    &-top > .ant-tabs-nav::before {
      border-bottom: 0;
    }
  }
}

// Table
.ant-table {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 30px;

  &-thead {
    th {
      &.ant-table-column-sort {
        background-color: transparent;
      }

      &.ant-table-column-has-sorters {
        &:hover {
          background-color: transparent;
        }
      }
    }

    tr,
    .ant-table-cell {
      background-color: #fff;
      border: 0;
      th {
        font-weight: 600;
      }

      .ant-table-column-sorters {
        justify-content: flex-start;
        gap: 5px;

        .ant-table-column-title {
          font-weight: 600;
          flex: 0 0 auto;
        }

        .ant-table-column-sorter {
          flex: 0 0 auto;

          &-inner {
            .anticon.active {
              color: #165151;
            }
          }
        }
      }

      th:not(:last-child):not(.ant-table-selection-column):not(
          .ant-table-row-expand-icon-cell
        ):not([colspan])::before {
        display: none;
      }
    }
  }

  td {
    &:hover {
      background-color: #f7fafa;
      cursor: pointer;
    }
  }

  .ant-table-cell {
    border: 0;
  }
}

// Pagination
.ant-pagination-item {
  &:hover {
    border-color: lighten($primary-color, 10%);

    a {
      color: lighten($primary-color, 10%);
    }
  }
}

.ant-pagination-item-active,
.ant-pagination-item-active a {
  border-color: $primary-color;
  color: $primary-color;

  &:hover {
    border-color: lighten($primary-color, 20%);
    color: lighten($primary-color, 20%);
  }
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: $primary-color;

  .anticon {
    color: $primary-color;
  }
}

// Drawer
.ant-drawer {
  &-header {
    background: var(--unnamed-color-024242) 0% 0% no-repeat padding-box;
    background: #024242 0% 0% no-repeat padding-box;
    opacity: 1;

    padding-inline: 70px;
    display: flex;
    align-items: center;
  }

  &-header-title {
    color: white;
    flex-direction: row-reverse;
    justify-content: space-around;
  }

  &-header-title &-title,
  &-header-title &-close {
    color: white;
    font-size: 24px;
    font-weight: 600;
    margin-right: 0;
    padding: 0;
  }

  &-body {
    padding: 40px 70px;
    margin-bottom: 90px;

    .drawer-footer {
      align-items: center;
      background-color: #fff;
      box-shadow: 0px 0px 20px #0000000a;
      display: flex;
      gap: 20px;
      height: 90px;
      inset: 0;
      justify-content: flex-end;
      padding: 10px;
      position: absolute;
      top: auto;

      &__cancel {
        color: #105f49;
        background-color: #fff;
        border: 0;
        font-weight: 600;
        outline: none;
      }

      &__submit {
        min-height: 50px;
        min-width: 100px;
      }
      &__add {
        height: 50px;
        width: 100px;
      }
    }
  }
}

// Modal
.ant-modal {
  &-content {
    border-radius: 12px;
  }

  .modal-footer {
    padding: 10px;

    &__cancel {
      &,
      &:is(:focus-visible) {
        color: #105f49;
        background-color: #fff;
        border: 0;
        font-weight: 600;
        outline: none;
      }
    }

    &__submit {
      height: 50px;
      width: 100px;
      outline: none;
    }
  }
}

.ant-spin-dot-item{
  background-color: $primary-color;
}
.ant-btn-primary:disabled {
  @include disabledButton;
}
