
@import "../../../styles/mixins";
@import "../../../styles/variables";

.approved-loans {
    .approved-extra-content {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-70px);
    }
    .approved-extra-content.disbursement-loans {
      gap: 4rem;
    }
    .search-input .ant-input-affix-wrapper {
        min-width: 200px;
      }
}

.disbursementConfirmationModal {
  h1 {
    font-size: 2.4rem;
    line-height: 2.6rem;
    margin-bottom: 0;
  }
  .ant-modal-body {
    padding: 0;
  }
}

.preferred-channel-btn {
  background-color: $app-primary-green;
  color: $white;
  font-weight: 500;
  height: 100%;

  &:hover {
    background-color: $app-primary-green;
    color: $white;
    font-weight: 500;
    height: 100%;
  }
  &:disabled {
    @include disabledButton;
  }
}

.approved-loans {
  .ant-tabs-nav-operations {
    .anticon {
      display: none;
    }
  }
  .ant-tabs-nav {
    width: 0;
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      display: none;
    }
  }
}
.loan {
  .ant-table {
    padding: 0;
    th {
      white-space: nowrap;
    }
  }
  &__selected-loans {
    display: flex;
    align-items: center;
    flex-basis: inherit;
  }
  &__text {
    font-size: 16px;
    color: $black;
    font-weight: bold;
  }
}

.download-icon {
  height: 15px;
}

.loan-btn {
  border-radius: 32px;
  opacity: 1;
  height: 45px;
  width: auto;
  border: none;
}
.approved-loans-picker {
    margin-bottom: 30px;
}

.approved-loans .approved-top {
    top: -115px;
}

.next-button {
    margin-left: 40px;
}
.extra-margin {
    margin-top: 50px;
}
.approved-loans-picker-with-details {
    margin-top: 50px;
}

.loan-disburse {
  justify-content: space-between !important;
}
.loan-disburse-btn {
  @extend .loan-btn;
  background: $link-color;
  &:hover {
    background: $link-color;
  }
  &__next {
    text-align: right !important;
    justify-content: flex-end !important;
  }
}
.loan-status-btn {
  background: $link-color;
  border: none;
  &:hover {
    background: $link-color;
  }
  height: 42px !important;
}
.loan-export-btn {
  width: 180px;
  height: 42px;
  background: #105f49;
}
