@import "../../styles/variables";
@import "../../styles/mixins";

.login,
.forgot-password,
.verify-otp,
.reset-password,
.create-password {
  $root-class: &;
  height: 100vh;
  &__logo-container {
    background-color: $primary-color;
    height: 100vh;
    @include flex-center();
    img {
      max-height: 220px;
      width: 170px;
    }
  }

  background-color: #fff;
  &__form {
    @include flex-center();
    padding: 6rem;

    .ant-form-vertical {
      @include flex-center(30px, column);
      align-items: stretch;
      min-width: 450px;

      .ant-form-item {
        margin-bottom: 0;
      }

      input {
        min-height: 50px;
      }

      h1 {
        align-self: flex-start;
      }

      button[type="submit"] {
        min-height: 64px;
      }
    }
  }
  &__forgot-password {
    align-self: center;
    color: $primary-color;
    font-size: 15px;
    font-weight: 500;
  }
}
