@import "../../../../../styles/variables";

.loan-details {
  position: relative;

  &__badge {
    background-color: #f8f8d1;
    border-radius: 8px;
    padding: 6px;
  }
  
  &__bc-status {
    background-color: $status-color;
    border-radius: 8px;
    color: $status-text;
    padding: 6px;
  }
}

.loan-summary {
  &__content {
    > .card {
      > .ant-row.block:first-child {
        > div:last-child {
          align-items: center;
        }
      }
    }
  }
}

.info-icon {
  padding-bottom: 3px;
}

.disbursed-amount-title {
  margin-top: 10px;
  margin-bottom: 20px;
  border-top: 1px solid $table-border;

  p:first-child {
    margin-top: 10px;
  }
}

.prev-loan {
  font-size: 11px;
}

.title {
  padding-left: 10px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content-container {
  min-width: 260px;

  .disbursed-amount {
    color: $primary-text;
    font-weight: 600;
  }
}

.ant-popover-placement-bottomRight {
  left: 1067px;
  top: 360px ;
  .ant-popover-inner {
    border-radius: 10px;
  }
}

.ant-popover-open.dedution-class{
  background-color: red;
  position: absolute;
}

.disbursement-titles {
  .ant-space {
    .ant-space-item {
      p {
        margin-bottom: 5px;
      }
    }
  }
}

.loans-popover {
  position: relative;
  display: inline-block;

  .deductions-popover{
    position: absolute;
    left: 30px;
    top: 10px;
  }

  .disbursement-amount-popover {
    position: absolute;
    left: 30px;
    top: 10px;
  }
}

.disbursement-row {
  .ant-col:nth-child(2) {
    padding-left: 0 ! important;
  }
}
.vivitri-change-modal {
  .ant-modal-body {
    padding: 0 2.5rem;
  }
}