@import "../../../styles/variables";
@import "../../../styles/mixins";

.branch-form,
.branch-detail-form,
.branch-collection-form,
.branch-location-form {
  @include flex-container(30px, column);

  &__header {
    @include flex-center();
    justify-content: flex-start;

    &__process {
      &-count {
        background-color: #165151;
        border-radius: 100%;
        color: #fff;
        height: 32px;
        width: 32px;
        @include flex-center();
      }

      &-title {
        color: $primary-color;
        font-size: 24px;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }

  &__content {
    form {
      @include grid-1Xn(2, 30px, 20px);

      .ant-form-item {
        margin-bottom: 0;
      }

      .branch-form__content__footer {
        grid-column: 2 / span 1;
      }
    }
  }
}
