@import '../../../styles/variables';

.crif-personal-details {
    .card {
        background-color: $white;
        margin-bottom: 20px;

        .line {
            margin-left: -32px;
            margin-right: -32px;
        }

        .word-break {
            max-width: 310px;
            word-break: break-all;
        }

        .address-col {
            max-width: 200px;
            word-break: break-all;
        }
    }

    .line-row {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border-line;
        margin-left: -32px;
        margin-right: -32px;

        .text-gray {
            margin-left: 30px;
        }
    }

    .variation-container {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .date {
        color: $tab-color;
        font-size: 12px;
    }

    .name {
        color: $black;
        font-size: 14px;
    }


}