.payments {
  .ant-pagination-options {
    display: none;
  }

  .tab-extra-content {
    margin-bottom: 20px;
    display: flex;
  }
}
