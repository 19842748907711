@import "../../../styles/variables";

.submit-button {
  padding: $action-button-padding;
  background-color: $white;
}

.download-report-modal {
  .ant-modal-title {
    h1 {
      line-height: 3.2rem;
    }
  }
  .ant-modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}