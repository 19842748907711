@import "../../../styles/variables";
@import "../../../styles/mixins";

.column-options {
  @include flex-container(12px, column);

  &__desc {
    font-size: 14px;
    color: $primary-black;
    margin-bottom: 1rem;
  }

  &__item {
    @include flex-container(15px);
    align-items: center;
    margin-bottom: 1.5rem;

    &__label {
      background-color: $input-bg;
      padding: 18px 24px;
      border-radius: 10px;
      flex-grow: 1;
    }
    &__delete {
      cursor: pointer;
      font-size: 18px;
      font-weight: 300;
      color: $danger-color;
    }
  }

  &__add {
    color: $link-color;
    cursor: pointer;
  }
}
