.branch-summary {
    &__label {
        font-size: 14px;
        text-transform: capitalize;
        color: #525252;
    }

    &__value {
        font-size: 15px;
        font-weight: bold;
    }
}