@import "../../styles/mixins";

.app-layout {
    .sider {
        background: #024242;
        height: 100vh;
        inset: 0;
        overflow: auto;
        padding: 20px;
        position: fixed;
        right: auto;

        .ant-layout-sider-children {
            @include flex-container(10px, column);

            & div:last-of-type {
                margin-top: auto;
            }
        }

        &__logo {
            @include flex-center();
            background-color: #165151;
            border-radius: 18px;
            height: 100px;
            margin-bottom: 20px;
            width: 165px;

            img {
                height: 36px;
                width: 120px;
            }
        }

        &__item {
            padding: 10px;
            text-transform: capitalize;

            a {
                align-items: center;
                display: flex;
                gap: 10px;
            }

            &__icon {
                background: rgba(119, 230, 181, 0.1);
                border-radius: 10px;
                height: 34px;
                padding: 4px;
                width: 34px;
            }
        }

        &--active {
            background-color: #165151;
            border-radius: 12px;
            position: relative;

            &::before {
                border-left: 3px solid #fbb43a;
                border-radius: 2px;
                bottom: 0;
                content: "";
                height: 28px;
                left: -1%;
                position: absolute;
                top: 25%;
            }

            .sider__item__icon {
                background-color: #fff;
            }
        }
    }

    .ant-layout-header {
        background-color: #f2f8f8;
        height: auto;
    }
}