@import "../../styles//variables";
.reports {
  text-align: center;
  .report-btn {
    height: 52px;
    width: 100%;
  }

  .custom-progress {
    .ant-progress-outer {
      .ant-progress-inner {
        border-radius: 8px !important;

        .ant-progress-bg {
          background-color: $green;
        }
      }
    }
  }
}
