@import "../../styles/mixins";
@import "../../styles/variables";

.loan {
  @include flex-container(10px, column);

&__book-debt-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
  .search-input {
    .ant-input-affix-wrapper {
    min-width: 20rem;
  }
}

  &__header {
    @include flex-container(18px);
    align-items: center;

    .d-flex {
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  &__details-container {
    padding-top: 2rem;
    display: flex;
  }
}

.book-debt__modal {
  .book-debt-modal__add-button {
    background-color: $blue-text;
    color: $white;
    padding: 1.8rem;
    @include flex-center();

    &:disabled {
      @include disabledButton;
    }
  }
  .drawer-footer {
    margin-top: 1.5rem;
    text-align: right;
    &__cancel {
      min-height: 50px;
      min-width: 100px;
      background-color: $white;
      border: none;
      color: $primary-color;
      font-weight: 600;
    }
    &__submit {
      &:disabled {
        @include disabledButton
      }
      min-height: 50px;
      min-width: 100px;
      background-color: $primary-color;
      color: $white;
    }
  }
}