@import "../../../styles/variables";

.key-factor {
  height: 90px;
  a {
    color: inherit;
  }

  .container {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;


    p {
      margin: 0;
    }

    .flex {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  .text-container {
    flex-grow: 1;
    display: flex;
    align-items: end;
    flex-flow: column nowrap;

    .payment-status {
      font-size: 12px;
    }

    .main-text {
      display: flex;
      align-items: center;
      gap: 2px;

      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 700;
      }

      span:first-child {
        font-size: 12px;
        color: $secondary-color;
      }
      .credit-score-flex {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }

    .tag-text {
      font-size: 12px;
    }
  }
}

.right-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
