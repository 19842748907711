.terms-and-conditions {
  padding: 2rem;
  text-align: justify;
  text-justify: distribute;

  &__list {
    li {
      margin-bottom: 1rem;
      margin-left: -20px;
    }
  }
}
