.verify-otp {
  &__form {
    &-content {
      input {
        width: 64px !important;
        height: 64px;
        background: #f3f5f7 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 10px;
        opacity: 1;
        margin-right: 1rem;
      }
      :focus {
        outline: none;
      }
    }
  }
}
