.table-header {
    display: flex;
    justify-content: space-between;
  }
  
  .payment-types-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    font-size: 1.2rem;
  }
  
  .payment-type {
    display: flex;
    gap: 0.6rem;
  }
  