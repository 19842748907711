@import "../../../styles/variables";
@import '../../../styles/mixins';

.ugro-doc-drawer {
  .document-upload {
    .attachment-upload__input {
      height: 61px;
      width: 200px;
      margin-top: 2rem;
      border: 1px dashed $attachment-border;
      border-radius: 12px;

      .attachment-upload__content {
        display: flex;
        margin-left: 20px;
        margin-right: 20px;
        gap: 10px;

        p {
          padding-top: 0;
        }
      }
    }
  }
  .ugro-doc-submit {
    button {
      width: 192px;
      height: 44px;
      background-color: $ugro-form-button;
      color: $ugro-form-button-text;
      border: none;
      font-weight: 600;
      letter-spacing: 0.9;

      &:disabled {
        @include disabledButton;
      }
    }
  }
  .document-card {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: $document-card-bg;
    width: 296px;
    padding: 20px;

    .file-name-container {
      padding-top: 3px;
      padding-left: 10px;
      padding-bottom: 20px;
      display: flex;
      gap: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
      min-width: 166px;
      min-height: 29px;
      word-break: break-all;
      background-color: $white;
      border: 1px solid $document-card-border;
      border-radius: 6px;

      p {
        padding-top: 4px;
        font-size: 12px;
      }
    }
  }

  .document-card-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__name {
      color: $black;
      font-weight: 600;
    }
  }
}
