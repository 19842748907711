.loan-types {
  position: relative;
  .loan-types-extra-content {
    position: absolute;
    top: -70px;
    right: 12.9%;
  }
}
.hidden {
  display: none;
}
