@import "variables";

/* Helpers CSS */

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.break-word {
  word-break: break-word !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.font-600 {
  font-weight: 600;
}

.text-bold {
  font-weight: bold;
}
.text-normal {
  font-weight: normal;
}

.text-underline {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none;
}

.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $secondary-color;
}

.text-success {
  color: $success-color;
}

.text-danger {
  color: $danger-color;
}

.text-warning {
  color: $warning-color;
}

.text-light-grey {
  color: $light-grey;
}
.text-heading {
  color: $heading-color;
}

.text-dark-grey {
  color: $dark-grey;
}

.text-white {
  color: #ffffff !important;
}
.text-link {
  color: $link-color;
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}
.text-italic {
  font-style: italic;
}

.bb-5 {
  border-bottom: ($spacer-y * 1.25) solid $primary-color !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.primary-black {
  color: $primary-black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

/* Padding */

.p-0 {
  padding: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

/* Margins */

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: ($spacer-y * 0.25) !important;
}

.mt-2 {
  margin-top: ($spacer-y * 0.5) !important;
}

.mt-3 {
  margin-top: ($spacer-y * 0.75) !important;
}

.mt-4 {
  margin-top: ($spacer-y * 1) !important;
}

.mt-5 {
  margin-top: ($spacer-y * 1.25) !important;
}

.mb-2 {
  margin-bottom: ($spacer-y * 0.5) !important;
}

.mb-3 {
  margin-bottom: ($spacer-y * 0.75) !important;
}

.mb-4 {
  margin-bottom: ($spacer-y * 1) !important;
}

.mb-5 {
  margin-bottom: ($spacer-y * 1.25) !important;
}

.ml-2 {
  margin-left: ($spacer-x * 0.5) !important;
}

.ml-3 {
  margin-left: ($spacer-x * 0.75) !important;
}

.ml-4 {
  margin-left: ($spacer-x * 1) !important;
}

.ml-5 {
  margin-left: ($spacer-x * 1.25) !important;
}

.mr-1 {
  margin-right: ($spacer-x * 0.25) !important;
}

.mr-2 {
  margin-right: ($spacer-x * 0.5) !important;
}

.mr-3 {
  margin-right: ($spacer-x * 0.75) !important;
}

.mr-4 {
  margin-right: ($spacer-x * 1) !important;
}

.mr-5 {
  margin-right: ($spacer-x * 1.25) !important;
}
.w-30 {
  width: 30px !important;
}

.h-100 {
  height: 100% !important;
}

.gap-3 {
  gap: ($spacer-x * 0.75) !important;
}


.d-flex {
  display: flex;
}
.align-item-center {
  display: flex;
  align-items: center;
}

.align-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
.custom-scrollbar {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}

.card-label {
  color: $label-green;
  font-size: 13px;
  font-weight: 600;
}

.text-gray {
  color: $text-gray;
}

.text-blue {
  color: $blue-text
}

.text {
  &__green {
    color: $green-text;
  }
  &__red {
    color: $danger-color;
  }
  &__grey {
    color: $tab-color;
  }
  &__label-green {
    color: $label-green;
  }
  &__label {
    font-size: 12px;
    color: $tab-color;
  }
  &__value {
    font-size: 14px;
  }
}

.crif-badge {
  border-radius: 8px;
  padding: 3px;
  margin-left: 10px;
  font-size: 8px;
  &__good {
    color: $status-active-text;
    background-color: $status-bg;
  }
}

.font-weight {
  &__600 {
    font-weight: 600;
    .text__label {
      font-weight: initial;
    }
  }
  &__400 {
    font-weight: 400;
  }
}

.font-size {
  &__18 {
    font-size: 18px;
  }
}

.line {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #d8e5e1;
}

.card {
  border-radius: 8px;
  box-shadow: 0px 10px 20px #0000000a;
  background-color: #fff;
  padding: 36px 34px;
}

.question-mark {
  &::after {
    content: "?";
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.content-box {
  height: auto;

  background: #f2f8f8 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 34px 36px !important;
}
.pop-btn {
  text-align: center;
  color: black;
  border-radius: 8px;
  padding: 1rem;
  background: #f2f8f8 0% 0% no-repeat padding-box;
  border: 0.5px solid #d8e5e1;
}

.size-16 {
  font-size: 16px;
}
.size-20 {
  font-size: 20px;
}

.block {
  font-size: 14px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d8e5e1;

  &__heading {
    color: $heading-color;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
  }

  &__label {
    color: $secondary-color;
    text-transform: capitalize;
  }
  &__name {
    color: $light-green;
    text-transform: capitalize;
  }

  &__value {
    color: #000;
    font-weight: bold;
  }

  &__image {
    @include flex-container(15px, column);

    img {
      border-radius: 12px;
    }
  }
}
.btn-primary {
  background-color: $heading-color;
  width: 200px;
  letter-spacing: 0.9px;
  height: 45px;
}
.btn-tertiary {
  background-color: $link-color !important;
  border: none;
  width: 200px;
  letter-spacing: 0.9px;
  height: 45px;
}
.btn-header {
  background-color: $link-color !important;
  border: none;
  padding: 2rem;
  @include flex-center();
  &:disabled {
    background-color: $input-bg !important;
  }
}

.status {
  &--closed {
    color: $red-text;
  }
  &--active {
    color: $green-text;
  }
}

.active-status {
  @include flex-center(0);
  justify-content: flex-start;
  margin-left: 10px;

  &--submitted,
  &--ct-verification,
  &--due-upcoming {
    color: #1193d8;
  }
  &--linked {
    color: #d9b51c;
  }
  &--loan-agreement-pending {
    color: $status-pending;
  }
  &--loan-agreement-uploaded, &--agreement-uploaded-and-confirmed, &--api-login-successful, &--ugro-api-success, &--ugro-document-upload-successfully {
    color: $status-approved;
  }
  &--mas-api-failure, &--mas-rejected, &--ugro-api-failed, &--ugro-document-upload-failed, &--od-payments {
    color: $danger-color;
  }
  &--mas-api-in-progress, &--file-approved-and-agreement-pending, &--loan-disbursed, &--loan-disbursement-in-progress, &--loan-agreement-upload-in-progress {
    color: $warning-color;
  }
  &--loan-agreement-uploaded {
    color: $status-approved;
  }
  &--mas-api-failure, &--mas-rejected, &--pre-closed-claims {
    color: $danger-color;
  }
  &--mas-api-in-progress, &--partial-principal-paid, &--partial-interest-paid, &--partially-paid, &--full-interest-paid, &--interest-paid {
    color: $warning-color;
  }
  &--loan
  &--disbursed,
  &--advance,
  &--due-paid,
  &--approved {
    color: #5dcc18;
  }
  &--linked {
    color: #d9b51c;
  }

  &--due-today {
    color: #5723cc;
  }
  &--due-paid {
    color: #158d35;
  }

  &--on-time {
    color: #158d35;
  }

  &--rejected,
  &--overdue,
  &--pre-closed,
  &--blocked,
  &--for-claims,
  &--false,
  &--late {
    color: $danger-color;
  }
  &--no-info {
    color: $black;
  }
  &--disbursement-in-progress,
  &--pending,
  &--late-payments,
  &--written-off {
    color: #d9b51c;
  }

  &::before {
    content: "";
    display: list-item;
    list-style-type: disc;
    margin-right: -5px;
  }
}

.tag-text {
  &--Excellent, &--Good, &--Average {
    color: $success-color;
  }
}
.hidden {
  visibility: hidden;
}

.ant-switch-checked {
  background-color: $primary-color !important;
}

.spin-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.custom-range-picker {
  border-radius: 12px;
  height: 42px;
  min-width: 250px;
}

.custom-table {
  table,
  th,
  td {
    border: 0.75px solid $table-border;
    padding: 12px;
    text-align: left;
  }
  width: 100%;
}
.download-button {
  padding: $action-button-padding;
}