@import "../../../styles/mixins";

.customer-detail {
  @include flex-container(25px, column);

  .pop-btn {
    background-color: #ffff;
  }

  .verify-btn {
    height: 42px;
  }

  &__modal {
    .ant-modal-content {
      padding: 2rem;
      resize: auto;

      .customer-detail__footer {
        margin-top: 4rem;
      }
    }

    &.mas-form-modal {
      position: absolute;
      top: 3%;
      left: 25%;

      .ant-modal-content {
        width: 804px;
      }
    }
  }
}

.ant-form-item-label > label::after{
    content: "";
  }

.credit-score-form {
  margin-top: 20px;
  .ant-form-item {
    margin-bottom: 10px;

  }
  .input__error {
    margin-bottom: 10px;
    margin-left: 5px;
  }
}

.loan-agreement-button {
  width: 250px;
}

.vivitriForm {
  margin-top: 2rem;
}