/* Color palette */
$primary-color: #024242;
$secondary-color: #525252;
$light-white: #ffffff40;
$primary-light-color: #0d494e10;
$primary-black: #4b4b4b;
$dark-blue: #22509d;
$black: #000000;
$white: #ffffff;
$light-grey: #00000015;
$dark-grey: #8898aa;
$light-green: #849691;
$success-color: #158d35;
$green: #5ed5a0;
$danger-color: #c63838;
$warning-color: #f3b21b;
$primary-text:#165151;
$body-bg: #f5f8fa;
$table-bg: #f7fafc;
$input-bg: #f3f5f7;
$link-color: #2276ef;
$table-border: #d8e5e1;
$grey: #F4F4F6;
$status-color: #F8E2D0;
$status-text: #92652C;
$status-pending: #1668D9;
$crif-blue-text: #2276EF;
$status-approved: #5dcc18;
$primary-yellow: #f8f8d1;
$status-pending: #d9b51c;
$status-approved: #37C415;
$status-text: #92652C;
$status-color: #F8E2D0;
$text-grey: #BEBEBE;
$button-disabled: #C8DDFB;
$empty-document-text: #6A7286;
$claimed-badge-bg: #D1F7F8;
$claimed-badge-text: #22888D;
$claim-status-text: #158D35;
$modal-text: #4F4F4F;
$disabled-color:rgba(0, 0, 0, 0.25);
$attachment-border: #2276EF;
$ugro-form-button:#77E6B5;
$ugro-form-button-text:#105F49;
$document-card-bg: #F2F8F8;
$document-card-border: #afb4bb;
$credit-text: #6CE2AE;
$dark-green: #105E4A;
$box-bg: #F2F8F8;
$red-text:#E54629;
$blue-text: #2276EF;
$status-bg: #EBF9E7;
$border-bg: #D8E5E1;
$label-green: #105F49;
$text-gray: #9B9B9B;
$green-text: #3AC627;
$border-line: #d8e5e1;
$meter-needle:#77E6B5;
$app-primary-green: #0E5F48;
$collapse-border: #DAE7E3;

/* Component colors */

/* Input Field */
$input-border-color: rgba(0, 0, 0, 0.164);
$input-border-color-hover: #006fba;
$input-border-box-shadow: 0 0 0 2px
  rgba(
    $color: $primary-color,
    $alpha: 0.1,
  );
$input-border-radius: 12px;
$input-hover-color: #046769;

/* Default Button */
$button-default-border-color: #024242;
$button-default-bg-color: #024242;
$button-default-text-color: #fff;
$button-default-border-radius: 32px;

/* Primary Button */
$button-primary-border-color: #024242;
$button-primary-bg-color: #024242;
$button-primary-text-color: #fff;
$button-primary-border-radius: 32px;

/* Checkbox */
$checkbox-bg-color: #024242;
$checkbox-border-color: #c4c4c4;
$checkbox-hover-border-color: #024242;
$checkbox-text-color: white;
$checkbox-hover-text-color: #024242;

/* Radio */
$radio-border-color: rgb(202, 202, 202);
$radio-checked-border-color: #024242;
$radio-checked-bg-color: #024242;

/* Select */
$select-border-color: rgb(202, 202, 202);
$select-hover-border-color: #024242;
$select-focus-box-shadow: 0 0 0 2px
  rgba(
    $color: #024242,
    $alpha: 0.2,
  );

/* Shadows */
$primary-shadow: 0 8px 22px #0000001a;
$secondary-shadow: 0px 3px 6px #2c28281c;
$disbursement-shadow: 0px 10px 20px #0000000A;

/* Spacer variables */
$spacer-x: 1rem;
$spacer-y: 1rem;

/* Tabs */
$tab-color: #525252;
$tab-active-color: #ffffff;
$tab-border: #105f4929;

// Heading
$heading-color: #105f49;

/* Apexcharts */
$achart-fill-bg: #046769;
$achart-fill-select: #2e8182;
$achrt-fill-box: #6ce3ae;
$achart-fill-color: #79e5b5;

/* Badge */
$status-active-bg: #E9F9F0;
$status-active-text: #14A02A;
$action-button-padding: 1rem 2rem 3rem;

/* Spinner */
$document-spinner-left-position: 11rem;
