.appliances {
  &__wrapper {
    span {
      background-color: #f2f8f8;
      padding: 8px;
      border-radius: 8px;
      margin-right: 30px;
    }
  }
}
