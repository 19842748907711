@import 'src/styles/variables';

.security-details {
    .link {
        color: $link-color;
        font: normal normal 12px/41px;
        font-weight: 600;
    }
    .mod-document-modal {
        .ant-modal-title {
            display: none;
        }
    }
}