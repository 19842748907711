.tablist {
  margin-left: 20px !important;
  margin-top: 22px !important;
}

.imgStyle {
  height: 40px;
}
.inputForBranch {
  padding-right: 40px;
}
.sunny {
  float: right;
}
.ant-tabs-nav-list .style {
  background: yellow;
  background-size: 40px 30px;
}
.ant-tabs-nav-list {
  /* border: 2px solid; */
  border-radius: 5pxs;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0px !important;
}
.ant-tabs-tab {
  border: 1px solid grey;
}
.ant-tabs-ink-bar {
  background: none;
}
.ant-tabs-tab:hover {
  color: #000 !important;
}
.ant-tabs-tab:hover {
  /* background-color: #105F49 !important; */
  /* border: none !important; */
  color: #fff;
}
.float-right {
  /* margin-bottom: -16px; */
}

.ant-tabs-tab-active {
  background-color: #105f49 !important;
  color: #fff !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.ant-tabs-tab {
  padding-block: 0;
  height: 40px;
  display: flex;
  align-items: center;
}

.ant-tabs-extra-content .float-right {
  display: flex;
  gap: 8px;
}
.ant-tabs-extra-content .float-right div {
  margin-right: 0;
}
.ant-tabs-extra-content .float-right .border {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blacklist-pincode-input {
  .ant-form-item {
    margin-bottom: 10px;
  }
}
