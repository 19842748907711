@import "../../../styles/mixins";
@import "../../../styles/variables";

.credit-report-wrapper {
    margin-top: 20px;
  .card {
    background-color: $button-default-bg-color;
    padding: 10px 30px;

    .container {
      align-items: center;

      svg .label text:nth-child(2) {
        transform: rotate(0deg) translate(35%, 15%);
      }

      svg .label text:nth-child(1) {
        transform: rotate(0deg) translate(-35%, 15%)
      }

      .score-container {
        margin-left: 30px;
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .name-container {
        p {
          margin: 0;
        }

        p:first-child {
          font: normal normal 300 13px/16px;
          color: $white;
          font-weight: 300;
        }

        p:last-child {
          font: normal normal 600 13px/16px;
          color: $white;
          font-weight: 600;
        }
      }

      .credit-score {
        font-size: 60px;
        color: $white;
        letter-spacing: 3px;
      }

      .credit-rating {
        font-size: 23px;
        font-weight: 400;
        letter-spacing: 0px;
        color: $credit-text;
      }
    }

    .flex-end {
      display: flex;
      justify-content: flex-end;
    }
  }

  .tab-container {
    margin-top: 30px;
  }

  .first-row {
    margin-bottom: 30px;
  }
}

.card.key-factors-container {
  background-color: $white;
  
  .payment-status-container, .monthly-emi-container, .total-write-off-container, .od-container {
    border-bottom: 1px solid $border-bg;
  }
}
