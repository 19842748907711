@import "../../../../../styles/variables";

.loan-type-configuration {
  &__cancel-btn {
    color: #105f49;
    background-color: #fff;
    border: 0;
    font-weight: 600;
    outline: none;
    &:hover {
      background-color: #fff;
      color: #105f49;
    }
  }

  &__submit-btn {
    height: 50px;
    width: 100px;
  }
  .arrow {
    cursor: pointer;
  }
  &__eligibility {
    height: 450px;
  }
}
