.user-access__name {
  margin-bottom: 4rem !important;
  justify-content: space-between;
}
.user-access__role {
  justify-content: space-between;
}
.ant-table {
  padding: 0;

  th,
  td {
    white-space: nowrap;
  }
}
