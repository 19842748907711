.loan-deduction-form {
  &__add-btn {
    .ant-btn {
      height: 58px;
      width: 150px;
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
  }
}