@import "src/styles/variables";
@import "src/styles/mixins";

.loan-claims-wrapper {

    .initiated-claims,
    .processing-claims,
    .claimed-claims {

        .initiated-claims-extra-content,
        .processing-claims-extra-content,
        .claimed-claims-extra-content {
            display: flex;
            position: absolute;
            top: -70px;
            right: 0;
        }
    }

}

.process-claim-modal {
    h1 {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 3rem;
        color: $modal-text;
        font: normal normal normal 15px/22px;
    }

    .ant-modal-body {
        padding: 0;
        padding-left: 30px;

    }

    .ant-modal-header {
        padding: 0;
        padding-left: 20px;
        margin-left: 20px;
    }

    .date-picker-container {
        width: 450px;
    }

    .process-claims-submit-button {
        background-color: $primary-color;
        color: $white;

        &:disabled {
            @include disabledButton;
          }
    }

    .ant-modal-body {
        margin-right: 20px;
    }

    .ant-modal-footer {
        padding: 5px 16px;
    }
}