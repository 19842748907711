@import "../../../styles/variables";

.empty-document {
    height: 130px;
    width: 211px;
    border: 1px dashed #525252;
    border-radius: 12px;
    padding: 10px;
    background-color: $input-bg;
  
    .empty-document-content {
      padding: 10px;
      padding-top: 3rem;
      color: $empty-document-text;
      text-align: center;
    }
  }