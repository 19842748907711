@import "../../styles/mixins";

.users {
  @include flex-container(20px, column);

  &__header {
    align-self: flex-end;
  }
}

.user-form {
  .user-form__container {
    .ant-form-item {
      margin-bottom: 5px;
    }
    .input__error {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}