@mixin flex-container($gap: 10px, $direction: row) {
    display: flex;
    flex-direction: $direction;
    gap: $gap;
}

@mixin flex-center($gap: 10px, $direction: row) {
    @include flex-container($gap, $direction);
    align-items: center;
    justify-content: center;
}

@mixin grid-1Xn($column-count, $column-gap: 10px, $row-gap: 10px) {
    column-gap: $column-gap;
    display: grid;
    grid-template-columns: repeat($column-count, 1fr);
    row-gap: $row-gap;
}

@mixin disabledButton {
    color: $disabled-color;
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }

@mixin flexWrapCenter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}