@import "../../../styles/variables";
@import "../../../styles/mixins";

.total-accounts-component {

  .ant-collapse {
    background-color: $white;
    border: 0.75px solid $border-bg;
    border-bottom: 0.75px solid $border-bg;
    border-radius: 8px;
  }

  .card {
    background-color: $white;

    .overall-score {
      @include flexWrapCenter;
      flex-direction: column;

      &__heading {
        font-size: 1.2rem;
        color: $label-green;
        font-weight: 400;
      }
    }

    .active-accounts {
      color: $black;

      &__heading {
        font-weight: 400;
        font-size: 1.2rem;
        color: $green-text;
      }
    }

    .closed-accounts {
      color: $black;

      &__heading {
        font-weight: 400;
        font-size: 1.2rem;
        color: $red-text;
      }
    }

    .total-accounts {
      color: $black;

      &__heading {
        font-weight: 400;
        font-size: 1.2rem;
        color: $blue-text;
      }
    }
  }
}