@import "../../../../styles/variables";
@import "../../../../styles/mixins";
.loan-type-details-form {
  @include flex-container(30px, column);

  &__header {
    @include flex-center();
    justify-content: flex-start;

    &__process {
      &-title {
        color: $primary-color;
        font-size: 24px;
        font-weight: bold;
        text-transform: capitalize;
      }
      &-count {
        background-color: #165151;
        border-radius: 100%;
        color: #fff;
        height: 32px;
        width: 32px;
        @include flex-center();
      }
    }
  }
}
