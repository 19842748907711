@import "../../../../styles/variables";
.repayment-chart {

  canvas {
    max-width: 100%;
    position: relative;
  }

  &__content {
    text-align: center;
    position: absolute;
    top: 48.5%;
    left: 50%;
    transform: translate(-50%, -50%);

    p:first-child {
      margin-top: 2rem;
    }
    h1 {
      color: $input-hover-color;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
    &-value {
      color: $link-color;
    }
    p{
      margin: 0;
    }
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}
