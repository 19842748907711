.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  &__page-info {
    display: flex;
    gap: 12px;
    align-items: center;

    &__image {
      background: #105f4915;
      border-radius: 12px;
      padding: 5px;
      width: 42px;
      height: 42px;
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__profile {
    &-container {
      border-left: 1px solid #d8e5e1;
      display: flex;
      align-items: center;
      height: 100%;
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 15px;
    padding-left: 16px;

    &__details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 2px;

      span {
        line-height: normal;
      }

      &__name {
        font-weight: bold;
        color: #000;
      }

      &__role {
        color: #525252;
        font-size: 12px;
      }
    }

    img {
      display: inline-block;
      border-radius: 20px;
    }
  }
}
