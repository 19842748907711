.pipeline-modal {
  .ant-upload-list-item-card-actions-btn {
    background-color: transparent;
    border: none;
  }

  .ant-upload-list-item-card-actions {
    background-color: transparent;
  }
  .upload-btn {
    width: 400px;
    height: 100px;
    background-color: transparent;
    color: black;
    border: 1px dotted black;
  }
  .import-btn {
    height: 40px;
    width: 110px;
  }
}
.pipeline {
  .search-input .ant-input-affix-wrapper {
    min-width: 250px;
  }
}