@import "../../../src/styles/variables";

.velicham-loan {
  background: transparent url("../../Assets/images/velichamBg.png") 0% 0%
    no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;

  .heading {
    color: #004622;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 8px;
  }

  .loan-container {
    width: 100%;
    max-width: 1134px; /* Changed max-width to 100% */
    min-height: 470px !important;
    height: auto;
    border-radius: 8px;
    background-color: $white;

    .header {
      height: 116px;
      background-color: #f5f5f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .title {
        font-size: 20px;
        font-weight: 600;
      }

      .logo {
        width: 162px;
        height: 93px;
      }
    }

    .loan-wrapper {
      margin-top: 50px;

      .ant-col {
        display: flex;

        justify-content: center;
      }

      &__content {
        &-box {
          width: 100%;
        }
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin: 5px 0;
          text-align: center;
        }

        input {
          width: 100%;
          max-width: 260px;
          height: 42px;
          background: $white 0% 0% no-repeat padding-box;
          border: 1px solid #cbd1d1;
          border-radius: 5px;
        }

        .ant-btn {
          // width: 100%;
          min-width: 133px;
          height: 41px;
          border-radius: 4px;
        }
      }
    }
  }

  .loan-details {
    padding: 0 30px 50px 30px;

    &__schedule {
      color: #2276ef;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .loan-btn {
      border-radius: 4px;
      width: 100%;
    }
  }
  .back {
    padding: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .loan-details__wrapper {
    span {
      display: block;
      .loan-details__schedule {
        margin: unset !important;
      }
    }
  }
  .velicham-loan {
    height: 100% !important;
  }
  .loan-details {
    margin-top: 50px;
    padding: 0 20px 20px !important;
  }
  .title {
    text-align: left !important;
    margin-top: 60px;
  }

  .loan-container {
    max-width: 100%;
    min-height: 100vh !important;
  }

  .loan-wrapper {
    padding: 1.5rem !important;
    &__label {
      margin-left: 0;
      margin-top: 30px;
    }

    &__content__box {
      display: none;
    }
  }
}
