@import "variables";
@import "mixins";
@import "helpers";
@import "@antOverrides";
@import url("https://use.typekit.net/ssz4mik.css");

/* General component styles */
html {
  font-size: 62.5%;
}

body {
  background: #f2f8f8;
  color: $primary-black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
  color: #000;
  font-family: 'acumin-pro', sans-serif !important;
}

h1 {
  font-size: 3.2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.ant-layout {
  background: #f2f8f8;
}

.verified-badge {
  @include flex-container();
  align-items: center;

  &::after {
    content: "";
    background-image: url("../Assets/images/verified.png");
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    display: inline-block;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
iframe#webpack-dev-server-client-overlay{display:none!important}