@import "../../../styles/variables";
.repayment-details {
  &__history {
    width: 480px;
  }
  .card {
    padding: 0;
    .ant-pagination {
      .ant-pagination-options {
        display: none;
      }
    }
  }

  .ant-table {
    padding: 0;
    th {
      white-space: nowrap;
    }
  }
  &__filter {
    .ant-select {
      height: 40px;
      .ant-select-selector {
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          line-height: 16px;
        }
      }
    }
    .ant-picker {
      width: 196px;
      height: 40px;
      border-radius: 8px;
    }
  }
  &__history-text {
    white-space: nowrap;
    display: block;
  }
  &__nach-btn {
    background-color: $link-color;
    border: none;
    width: 100px;
    letter-spacing: 0.9px;
    height: 38px;
    &:is(:hover, :focus, :active) {
      background-color: $link-color;
    }
  }

  &__nach-tab {
    .ant-tabs-tab-active {
      background-color: $link-color !important;
    }
    .ant-tabs-tab {
      width: 175px;
      height: 57px;
      margin-right: 50px;
      border-radius: 8px;
    }
    .ant-btn {
      width: 171px;
      height: 64px;
    }
  }
}
