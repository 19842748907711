.custom-image {
  img {
    border-radius: 12px;
    object-fit: cover;
    height: auto;
  }
}

.ant-modal-content {
  padding: 1rem;
}
