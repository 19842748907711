.pipeline-loans {
  .ant-pagination-options {
    display: none;
  }
}
.loan-amount-container {
  width: 500px;
  display: flex;
  gap: 40px;

  .total-loans-container, .total-amount-container {
    display: flex;
    align-self: center;
    justify-items: center;
    gap: 20px;

    p:nth-child(2) {
      margin-top: 3px;
      font-size: 16px;
    }
  }
}

.pipeline-loans {
  .pipeline-extra-content {
      display: flex;
      position: absolute;
      right: 0;
      top: -70px;
  }
  .search-input .ant-input-affix-wrapper {
    min-width: 250px;
  }
}
