@import "src/styles/variables";

.acknowledge-details-drawer {
    .document-details {
        margin-top: 30px;
        margin-bottom: 2rem;
    }

    .document-proof-container {
        margin-top: 40px;
        display: flex;
        margin-right: 30px;
        width: 189px;
        height: 50px;
        background-color: $grey;
        border-radius: 6px;
        padding: 0 3px;

        .proof-icon {
            height: 32px;
        }

        .text-container {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-items: center;

            span:nth-child(2) {
                padding-top: 3px;
                font-size: 14px;
            }
        }
    }

    .doc-location-form {
        margin-top: 10px;

        .ant-form-item {
            margin-bottom: 10px;
        }
    }

    .release-letter-upload__container {
        margin-top: 30px;

        .d-flex {
            display: flex;
            margin-left: 20px;
        }

        .document-proof-container {
            margin-top: 0;
            margin-right: 30px;
        }
    }

    .attachment-upload__input {
        border: 1px solid #525252;
        border-radius: 12px;
        opacity: 1; 
        width: 189px;
        height: 50px; 
    }

    .attachment-upload__content {
        img {
            margin-left: 25px;
            width: 16px;
            height: 19px;
        }
        p {
            padding-top: 1rem;
            font-size: 13px;
            color: $secondary-color;
          }
        display: flex;
        margin-top: -15px;
        gap: 20px;
        align-items: center;
    }

    .empty-space {
        height: 20px;
    }

    .doc-location-field {
        margin-top: 10px;
    }

    .release-document {
        .document-proof-container {
            margin-top: 10px;
        }
    }
}