@import "../../../styles/variables";
@import "../../../styles/mixins";

.branchAccountantUpload {
.ant-form-item-label {
  font-weight: 600;
  font-size: 1.6rem;
}
}

.loan-individual-details {
  &__dot {
    height: 36px;
    border-radius: 8px;
  }

  .extra-content {
    display: flex;
  }
  .wallet-btn {
    width: 249px;
    height: 42px;
    text-transform: capitalize;
    padding: 0 1rem;

    background: $green;
    border: none;
    border-radius: 8px;
    color: $black;

    img {
      width: 25px;
    }
  }
  .loan-disburse-btn {
    background: $link-color;
    &:hover {
      background: $link-color;
    }
    &__next {
      text-align: right !important;
      justify-content: flex-end !important;
    }
  }
}
.claimed-badge {
  background-color: $claimed-badge-bg;
  color: $claimed-badge-text;
}

.customer-claim-status {
  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
  color: $claim-status-text;
}

.claims-button-container {
position: absolute;
right: 0;

.claims-button {
  width: 167px;
  height: 42px;

  &:hover {
    background: $button-disabled;
    color: $white;
  }

  &:disabled {
    background-color: $button-disabled;
    color: $white;
  }

  &:not(:disabled):hover {
    background: $link-color;
  }
}
}

.cro-date {
color: $text-grey;
font-size: 12px;
}

.claim-document__label {
color: $empty-document-text;
}

.upload-modal-footer {
margin-top: 40px;
.drawer-footer__cancel, .drawer-footer__submit {
  min-height: 50px;
  min-width: 100px;
}

.drawer-footer__submit {
  background-color: $button-default-bg-color;
  color: $white;
}

.drawer-footer__submit:disabled {
  color: $disabled-color;
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
}

.process-claim-modal {
.processing-claim-form {
p {
  margin-bottom: 15px;
}

}}

.death-document-form {
.attachment-upload__input {
  height: 120px;
}

.document-name-input {
  .ant-form-item {
  margin-bottom: 10px;
}
}
}

.claims-document__upload-container {
  margin-top: -10px;
  margin-left: 30px;

  &.doc-holder {
    margin-top: 20px;
  }

  .attachment-upload__input {
        width: 272px;
        height: 130px
  }

  .attachment-upload__content {
    margin-top: 10px;
  }
}

.claim-document__container {
  margin-top: 30px;
  margin-bottom: 20px;
}

.claims-status-badge {
  font-size: 1.3rem;
  font-weight: 400;
}

.credit-report-btn {
  &:disabled {
   @include disabledButton;

    &:hover {
      @include disabledButton;
    }
  }
}

.loan-section-buttons {
  display: flex;
  gap: 1rem;
}