@import "../../../styles/variables";
@import "../../../styles/mixins";

.dash-stats {
  padding: 24px 26px;
  background-color: #046769;
  border-radius: 16px;

  &__picker {
    .ant-picker {
      width: 100% !important;
    }
  }

  &__heading {
    color: $body-bg;
    font-size: 28px;
  }
  &__stat {
    height: 150px;

    @include flex-container(10px, column);
    padding: 28px 20px;
    border-radius: 12px;
    background: radial-gradient(
      farthest-side at 100% 10%,
      #6ce4ae 60%,
      #77e6b5 60%
    );
    &__icon {
      width: 42px;
      height: 42px;
    }
    &__name {
      font-size: 18px;
      color: $primary-color;
    }
  }
  &__select {
    .ant-select {
      width: 265px;
      height: 50px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__apply-btn {
    width: 100px;
    height: 50px;
    background-color: $link-color;
    border: none;

    &:is(:hover, :focus, :active) {
      background-color: $link-color;
    }
  }
  &__box-heading {
    margin-bottom: 0;
  }
}
