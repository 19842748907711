.family-details {
  &__name,
  &__verify {
    display: flex;
    align-items: center;
  }
  .custom-table {
    th,
    td {
      width: calc(100% / 4);
    }
  }
}
