@import "../../styles/variables";

.dashboard {
  .ant-picker {
    height: 40px;
    width: 240px;
    background-color: $input-bg;
    input::placeholder {
      color: $black;
    }
    border: 0;
    border-radius: 6px;
  }
  .ant-select {
    .ant-select-selector {
      background-color: $input-bg;
      height: 40px;
      padding-right: 20px;
      border: 0;
      border-radius: 6px;
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: 18px;
        font-size: 13px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.ant-select.select-branches {
  .ant-select-selector {
    width: 170px;
  }
    .ant-select-arrow, .ant-select-clear {
      right: 5px;
    }
}

