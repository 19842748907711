@import "src/styles/variables";

.btn-filter {
  margin: 0 1.5rem 1.5rem 0;
  background-color: $white;
  color: $black;
  border: none;
  &:is(:hover, :focus, :active) {
    color: $black;
    background-color: $white;
  }
  .remove-filter {
    margin-left: 10px;
  }
}
.filter-buttons {
  display: flex;
  flex-wrap: wrap;
}
