@import "../../../../../styles/mixins";

.document {
    @include flex-container(15px);

    &__header {
        color: #105F49;
        font-size: 16px;
        font-weight: 600;
    }

    &__info {
        @include flex-container();
        align-items: center;
        cursor: pointer;
    }
}