@import "../../../../styles/mixins";

.loan-summary {
  @include flex-container(15px, column);

  .family-loan-table {
    .address-row {
      td {
        word-break: break-all;
      }
    }
  }

  &__verification {
    display: flex;
    justify-content: end;
  }

  .bank-details {
    border-bottom: 1px solid #d8e5e1;
  }

  .block--image-container {
    border: 0;
  }

  .customer-details {
    .ant-image-img {
      width: 200px;
    }
  }
}
.loan-summary__drawer {
  .ant-drawer-body {
    margin-bottom: 0;
  }
}
