.payment-redirection {
  background-color: #fdfefc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .centered-content {
    text-align: center;
  }
}
