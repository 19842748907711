@import "../../../styles/variables";

.branch-actions {
  .target-icon {
    margin-right: 5px;
  }
  .ant-btn {
    background-color: unset;
    border: 0 solid transparent;
    padding: 0;
    height: unset;
  }
}
.branch__popover-content {
  font-size: 14px;
  font-weight: normal;
  color: #343c6a;
  cursor: pointer;
  img {
    margin-right: 5px;
  }
}

.branches {
  position: relative;

  .branches-extra-content {
    position: absolute;
    top: -72px;
    right: 12.9%;
  }
}
