@import "../../../styles/variables";
.custom-modal {
  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }

  .ant-btn-default {
    background-color: transparent;
    color: $primary-color;
    border: none;
    font-weight: 600;
  }
}
.ant-modal-footer {
  .ant-btn {
    min-width: 100px;
    height: 50px;
  }
}
