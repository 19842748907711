@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.branch-target-form {
  @include flex-container(30px, column);
  .ant-form-item {
    width: 70%;
  }
  .field-icon-wrapper {
    @include flex-container(10px, row);
    $icon-width: 50px;
    width: calc(70% + 10px + #{$icon-width});
    align-items: center;
    & > div {
      width: 100%;
      .ant-form-item {
        width: 100%;
      }
    }
    .circular-icon {
      flex: 1;
      width: $icon-width;
      padding: 10px;
      border-radius: 50%;
      background-size: cover;
      background-color: #f2f8f8;
    }
  }
}
