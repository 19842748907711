@import "../../../styles/mixins";

.icon-container {
  @include flex-center();
  cursor: pointer;
  border: 1px solid #105f4929;
  border-radius: 6px;
  background-color: #fff;
  height: 42px;
  min-width: 42px;
}

.icon {
  cursor: pointer;

  img {
    width: 25px;
    height: 25px;
  }
}
