.assign-user {
  .ant-checkbox-group {
    width: 100%;
  }
  .ant-radio-group {
    width: 100%;
  }

  &__customer {
    margin-bottom: 5rem;
  }
  &__search {
    width: 30%;
  }
  .ant-pagination-options {
    display: none;
  }
}
