.titleHead .ant-table-thead {
  color: green;
}
.Centre-module {
  background-color: #f2f8f8;
}
.float-right {
  float: right;
}
.float-right img {
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.centers {
  position: relative;
  .centers-extra-content {
    position: absolute;
    top: -72px;
    right: 12.9%;
  }
}

.hi {
  color: red;
}

.float-right div {
  display: inline-block !important;
  margin-right: 10px;
}
.border {
  border: 1px solid #105f4929;
  padding: 11px 0px;
  margin-right: 10px;
  border-radius: 6px;
  background: #fff;
}
.ant-table-column-title {
  color: #000;
  font-weight: 500;
}
.red {
  color: #c63838;
}
.green {
  color: #158d35;
}

.centers {
  position: relative;
  .centers-extra-content {
    position: absolute;
    top: -72px;
    right: 12.9%;
  }
}