@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.personal-details {
  @include flex-container(20px, column);

  &__intro {
    &__details {
      @include flex-container(10px, column);
      justify-content: center;

      &__name {
        font-size: 42px;
      }

      &__status {
        color: #25aa49;
        font-size: 24px;
        margin-left: 25px;

        &--active {
          color: #25aa49;
        }

        &--inactive {
          color: $danger-color;
        }

        &--freezed {
          color: $warning-color;
        }
      }
    }

    &__pic {
      border-radius: 24px;
      box-shadow: 0px 3px 6px #00000029;
    }
  }

  &__list {
    img {
      border-radius: 100%;
      width: 27px;
      height: 27px;
    }
  }
  &__dot {
    width: 4rem;
    height: auto;
    text-align: center;
    color: black;
    border-radius: 8px;
    margin: 0 auto;
    padding: 1rem;
    background: #f2f8f8;
  }
  &__dot-details {
    cursor: pointer;
    width: 180px;
  }
  &__status-modal {
    .block {
      width: 100%;
      height: 0.1px;
      color: #d8e5e1;
    }
    .ant-modal-content {
      padding: 4rem;
      width: 508px;
      height: 358px;
      color: $body-bg;
      background: #efa822 0% 0% no-repeat padding-box;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .modal-btn__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .modal-btn__cancel {
        background-color: transparent;
        border: none;
      }
      .modal-btn__status {
        width: 15rem;
        min-height: 6rem;
        max-width: 15rem;
        white-space: wrap;
        border: none;
        font-size: 1.3rem;
        color: $black;
        background: $white 0% 0% no-repeat padding-box;
      }
    }
  }
  &__row {
    position: absolute;
    top: 62%;
    left: 50%;
    padding: 8rem;
    transform: translate(-50%, -50%);
    height: 300px;
    width: 508px;
    border-radius: 290px 290px 0 0;
    background-color: #c98c01;
  }
  &__modal {
    .enable-btn {
      background-color: $primary-color;
      color: $white;
      height: 65px;
      width: 149px;
    }
  }
}

.ant-popover-content {
  .ant-popover-content {
    .ant-popover-inner-content {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
