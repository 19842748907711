@import "../../../styles/variables";

.repayment-dashboard {
  &__stats {
    margin-right: 20px;
    &-row {
      justify-content: space-between;
    }
    background-color: $achart-fill-bg;

    &-label {
      color: $white;
      margin: 0 0 0.5rem 0.5rem;
    }
    &-box {
      width: 32%;
      float: left;
      padding: 2rem;
      background-color: $achrt-fill-box;
      height: 150px;
      border-radius: 12px;
      &-heading {
        color: $primary-color;
        margin-bottom: 0.5rem;
      }
      &-label {
        margin-bottom: 0.5rem;
      }

      &-description {
        color: $link-color;
      }
    }
  }
  &__modal {
    .ant-picker {
      height: 50px;
      border-radius: 12px;
    }
  }
}
