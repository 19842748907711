@import "../../../../styles/variables";

.comments {

  .ant-collapse {
    border: none;
  }

  .ant-collapse-item {
  border: none;
    
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-header {
    position: relative;
    background-color: $white;

    &:not(last-of-type)::after {
      content: "";
      height: 0.1rem;
      position: absolute;
      background-color: $collapse-border;
      bottom: 0;
      width: 97%;
    }
  }

  &__loanTypeName {
    color: $primary-color;
    font-weight: 600;
    padding-left: 1rem;
  }

  .user-img {
    width: 40px;
  }
  .line {
    opacity: 0.3;
  }
  &__input {
    height: 620px;
    &-title {
      margin-left: 20px;
      font-weight: 600;
    }
    &-box {
      padding: 2rem;
      .ant-input {
        height: 249px;
      }
    }
  }
  .btn-submit {
    width: 100px !important;
  }

  .ant-timeline-item {
    .ant-timeline-item-head {
      background-color: #105f49;
      border: none;
    }
  }
}
.documents-modal {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}