.stats-graph {
  margin-bottom: 16px;
  width: 100%;
  .custom-tooltip {
    padding: 1.5rem;
    .value {
      font-weight: bold;
    }
  }
}
