@import '../../../styles/variables';

.crif-credit-enquiries {
    .card {
        background-color: $white;

        .ant-table-tbody {
            .enquiry-date {
                color: $blue-text;
            }

            .enquiry-amount {
                font-weight: 600;
            }

            .loan-type {
                color: $tab-color;
            }
        }
    }
}