@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.change-status {
  &__row {
    padding: 1rem;
  }
  @include flex-container(30px, column);

  &__current {
    @include flex-container(20px);
    align-items: center;

    &__value {
      color: $primary-color;
      font-weight: bold;
      font-size: 18px;
    }
  }

  &__update {
    .ant-form-item-label {
      @include flex-center();

      label {
        min-width: 110px;
      }
    }
  }
}
