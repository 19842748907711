@import "src/styles/variables";

.accountant {
  .header {
    font-size: 17px;
    font-weight: 600;
  }
}
.accountant-reject-button {
  background: $white;
  color: $danger-color;
  font-weight: 600;
}
.accountant-reject-button:hover {
  background: $white;
  color: $danger-color;
  font-weight: 600;
}
.accountant-section,
.regularisation-details {
  .info-icon.accountant {
    padding-left: 5px;
  }

  .collection-proof-container {
    display: flex;
    width: 145px;
    height: 30px;
    background-color: $grey;
    border-radius: 6px;
    padding: 0 3px;
    .proof-icon {
      height: 22px;
    }

    .text-container {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-items: center;

      span:nth-child(2) {
        padding-top: 3px;
        font-size: 14px;
      }
    }
  }
}
.regularisation-buttons {
  margin-top: 150px;
}

.accountant-section {
  &.pending-requests, &.rejected-requests {
    position: relative;
    .extra-content {
      position: absolute;
      right: 0;
      top: -70px;
    }
  }
}