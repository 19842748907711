@import '../../../../styles/variables';

.payment-status {
    
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0;
    }
    .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
        padding: 0;
    }
}

.monthly-emi-card {
    .ant-col-offset-3:nth-child(3) {
        .text__value {
            color: $label-green;
            font-weight: 600;
            font-size: 18px;
        }
    }
}

.payment-status {
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
        background-color: $white;
    }
}

.key-img-container {
    margin-right: 10px;
}

.payments-content-collapse {
    background-color: $white;
    border: 0.75px solid $border-bg;
    border-bottom: 0.75px solid $border-bg;
    border-radius: 8px;
}